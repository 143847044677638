<template>
  <vue-tel-input v-model="phone"
    v-bind="bindProps" @on-input="trim" ref="inputTel"
    v-on:phone-changed="validate" v-on:country-changed="countryChanged" v-on:input="enable_button"
    v-bind:class="[loading? blurClass: '', bkClass]"
    style="margin: 0 10%; border-radius: 0;"></vue-tel-input>
  <button :disabled="btn1_dnabled" class="submit" @click="joinNumbers" v-bind:class="[loading? blurClass: '', bkClass]">ABMELDEN</button>
  <br />
  <div  class="phonediv" v-show="loading">
         <img class="transaction" id="img03" src="../assets/20230807_Joker-Kopf.svg" style="background-color:transparent; border:none; width: 16em;">
  </div>

  <div v-show="success" class="success" >{{success_msg}}</div>
  <div v-show="failure" class="failure" >{{failure_msg}}</div>
  <br />
  <br />
  <modal
      v-show="popupVisible"
      @bleiben="bleiben"
      @abmelden="abmelden"
    />
</template>

<script>
  import { VueTelInput } from 'vue-tel-input';
  import axios from "axios";
  import modal from './Modal.vue';
  export default {
    name: 'TelInput',
    components: {
        VueTelInput,modal,
      },
    data () {
      return {
        sessionID: '',
        countryCode: '',
        phone: '',
        formated: '',
        result: '',
        success : false,
        failure : false,
        success_msg : 'Abmeldung erfolgreich',
        failure_msg : 'Abmeldung fehlgeschlafen',
        loading: false,
        bkClass: 'bk',
        blurClass: 'blur',
        bindProps: {
           mode: "national",
           defaultCountry: "DE",
           disabledFetchingCountry: false,
           disabled: false,
           disabledFormatting: true,
           placeholder: "Handynummer eintragen",
           required: true,
           enabledCountryCode: false,
           enabledFlags: true,
           preferredCountries: ['de','ch','at'],
           onlyCountries: [],
           ignoredCountries: [],
           autocomplete: "off",
           name: "telephone",
           maxLen: 25,
           wrapperClasses: "",
           inputClasses: "",
           inputOptions: {
            showDialCode: false
          }
       },
       btn1_dnabled: true,
       popupVisible: false,
       popUpResult: false,
     }
    },
    // computed: {
    //   btn_disabled () {
    //       get: return this.btn_disabl();
    //       }
    // },

    methods: {
      countryChanged(countryCode) {
        this.countryCode = countryCode.dialCode;
      },
      btn_disabl () {
        if (this.$refs.inputTel === undefined) {
            return true
          }
        else{
          return `${!this.$refs.inputTel.phoneObject.valid}`;
          }
      },
      getSession () {
        var totalurl = "https://gmdr8ywk58.execute-api.eu-central-1.amazonaws.com/prod/sms/?mobileNumber="+this.result;
        this.loading = true;
        axios({method: 'GET', url: totalurl, responseType : "json"})
            .then(result => {
              //console.log(result);
              this.sessionID = result;
              }
              , error => console.error(error))
            .catch((error) => {
              console.log(error);
              this.failure = true;
            })
            .finally(() => {
              this.loading = false;
              if (this.failure === false)
              {
                this.success = true
                this.success_msg = 'Abmeldung von '+this.phone+' erfolgreich',
                this.btn1_dnabled = true
                this.phone = ''
              }
            });
      },
      abmelden(){
        this.popUpResult = true;
        this.popupVisible = false;

        this.getSession();
      },
      bleiben(){
        this.popUpResult = false;
        this.popupVisible = false;
        this.btn1_dnabled = true
        this.success = true;
        this.success_msg = 'Danke, dass du bei uns bleibst';
      },
      joinNumbers() {
        this.validate();
        this.result =  this.countryCode + this.formated;
        this.showModal();

        return this.result;

      },
      showModal() {
        this.popupVisible = true;
      },
      closeModal() {
        this.popupVisible = false;
      },
      trim() {
        this.phone = this.phone.replace(/\s/g, '');
      },
      validate() {
        var telephone = this.$refs.inputTel.phoneObject.formatted;
        if (telephone[0]=='0'){
          telephone = telephone.slice(1,telephone.length);
        }
        this.formated = telephone.replace(/\s/g, '');
      },
      enable_button() {
        this.btn1_dnabled = !this.$refs.inputTel.phoneObject.valid;
      }
    },
  }
</script>
<style>
.submit {
  appearance: button;
  border: 0;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  width: 10rem;
  font-family: inherit;
  background-color: #002f63;
  color: white;
  margin: 1em;
  padding: 2rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
.submit:disabled{
   background-color: #d6d6d6;
   color: white;
   cursor: no-drop;
}
.phonediv {
  box-sizing: inherit;
  animation: rotate 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  -webkit-animation: rotate 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  display: inline-flex;
  position: relative;
  bottom: 10rem;
}
.success {
  background-color: #C7ECDB;
  color: #22B473;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  max-width: 50rem;
  margin: 0 10%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.failure{
  background-color: #f6c4cf;
  color: #dc143b;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  max-width: 50rem;
  margin: 0 10%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bk {
  transition: all 0.1s ease-out;
}

.vue-tel-input:focus-within {
  @-webkit-box-shadow inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,47,99,.6) !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(0,47,99,.6) !important;
  border-color: #002f63 !important;
}

.blur {
  filter: blur(2px);
  opacity: 0.4;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            }
  75% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
            }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            }
  75% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
            }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


</style>
