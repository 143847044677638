<template>
  <div class="modal-backdrop">
    <div class="modal">

      <div class="header">
      Möchtest du dich wirklich abmelden?
      </div>


      <div class="modal-body">
      <button
          type="button"
          class="btn-back"
          @click="$emit('bleiben')"
        >
          Zurück
      </button>

      <button
          type="button"
          class="btn-ok"
          @click="$emit('abmelden')"
        >
          Abmelden
      </button>
    </div>
    </div>
  </div>  
</template>

<script>
  export default {
    name: 'Modal',
    
    methods: {
      
      close() {
        this.$emit('close');
      },
   },
   };
</script>
<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: block!important;
    align-content: stretch;
    padding: 50px!important;
  }

  .header{
    color: #002F63;
    font-size: 1.071rem;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
    /*text-transform: uppercase;*/
    padding-bottom: 2rem;
  }

  .modal-body button{
    font-size: 1rem;
    font-weight: 700;
    /* font-family: "Avenir", sans-serif; */
    display: inline-flex;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 10rem;
    height: 3rem;
    cursor: pointer;
    font-weight: bold;
    margin: 1rem 0.5rem 0rem 0.5rem;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  appearance: button;
  }

  .btn-back {
    color: #002F63;
    background: #FABB13;
    border: 2px solid #FABB13;
    /*border-radius: 5px;*/
    
  }

  .btn-ok {
    color: #002F63;
    background: #FFF;
    border: 2px solid #002F63;
    /*border-radius: 5px;*/
   }
  @media (min-width: 768px) {
    .modal-body {
    flex-direction: row !important;
    }
  }

  .modal-body {
    display: flex;
    /* flex-direction: column; */
    flex-direction: column;
    align-items: center;
  }

</style>
