<template>
  <img alt="Jokerstar" src="./assets/JOKERSTAR_rgb_pos_1line.svg" style="max-width: 30rem;">
  <h1 class="anleitung">Bitte trage deine Handynummer ein, um dich abzumelden</h1>
  <smsForm />
</template>

<script>
import smsForm from './components/smsForm.vue'


export default {
  name: 'App',
  components: {
    smsForm
  }
}
</script>

<style>
#app {
  font-family: Avenir, Avenir-Roman, Cheltenham, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
h1.anleitung {
  color: #939393;
  font-size: 1.2rem;
  font-weight: unset;
}
@font-face {
    font-family: 'Cheltenham';
    src: url("./assets/fonts/CheltenhamITCPro-BoldCond.otf");
    font-family: 'Avenir';
    src: url("./assets/fonts/AvenirLTStd-Black.otf");
    font-family: 'Avenir-Roman';
    src: url("./assets/fonts/AvenirLTProRoman/font.woff2");
    font-display: swap;
  }
</style>
